import { CLEAR_PERSISTED_CHOICES } from "../actions/actionTypes";

export const getBasename = () => {
  const path = window.routeInfo && window.routeInfo.basePath
    ? window.routeInfo.basePath
    : window.location.pathname;
  return path.split('/', 2).join('/');
};

export const getProtocolWithHost = () => {
  const url = document.location;
  return `${url.protocol}//${url.host}`;
};

export const getVanityPath = ({ id, shortLabel }) => `${id}-${shortLabel.replace(/[\W_]+/g, '-').replace(/^-+|-+$/g, '')}`;

const isParentSelected = (options, choices, option) => {
  if (!option.isDependent) {
    return true;
  }

  const choice = choices[option.parentChoiceId];
  if (choice && choice.isSelected) {
    const option2 = options[choice.optionId];
    return option2 && isParentSelected(options, choices, option2);
  }

  return false;
};

const getSelectedChoice = (options, choices, optionId) => {
  const option = options[optionId];
  return option && option.isActive
    && isParentSelected(options, choices, option)
    && option.choices.find((cid) => choices[cid].isSelected);
};

export const getChoicePaths = (options, choices) => {
  const selectedChoices = options.allIds
    .map((id) => getSelectedChoice(options, choices, id))
    .filter((x) => x);
    const parts = selectedChoices.map((id) => getVanityPath(choices[id]));

  return parts;
};

const getFallbackPath = () => window.location.pathname.split(/(.*?\d{8})(?:\/|$)/);

let paramArr = [];

export const getParamsFromUrl = () => {
  const search = typeof window !== 'undefined' ? window?.location?.search : 'env=prod';
  if (search !== '') {
    const params = search.substring(1);
    const parts = Array.isArray(params) ? params : (params || '').split('&');
    if (parts !== '') {
      parts.forEach(x => {
        const key = x.substr(0, x.indexOf('='))
        const value = x.split('=')[1]
        if (key === 'debug' || 'experience' || 'expversion' || 'env')
        if(key !== '' && value) {
          paramArr[key] = value;
        }
      })
    }
  }
  return paramArr;
}

export const getPathFromSelectedChoices = (configurator, basePath = undefined) => {
  let path = basePath;
  // let paramArr = [];
  if (!path) {
    if (window.routeInfo && window.routeInfo.basePath) {
      path = window.routeInfo.basePath;
    } else {
      const paths = getFallbackPath().filter(elm => elm);
      path = paths.length > 1 ? `${paths[0]}/${paths[1]}` : paths[0];
      // [, path] = getFallbackPath();
    }
  }

  getParamsFromUrl();
  const { options, choices } = configurator;
  const environmentParams = Object.keys(paramArr).map(key => `${key}=${paramArr[key]}`).join("&");
  const selectedChoices = getChoicePaths(options, choices).join('&');
  if (window.location.search !== selectedChoices) {
    if (environmentParams !== '') {
      return `${path}?${environmentParams}&${selectedChoices}`;
    } else {
      return `${path}?${selectedChoices}`;
    }
  }
  return '';
};

export const getChoicePathsIfSelected = (options, choices, choiceId) => {
  const selectedChoices = options.allIds
    .map((oid) => {
      const optionChoices = options[oid].choices;
      if (optionChoices.includes(choiceId)) {
        return choiceId;
      }

      return optionChoices.find((cid) => choices[cid].isSelected);
    })
    .filter((x) => x);
  const parts = selectedChoices.map((id) => getVanityPath(choices[id]));
  return parts;
};

export const getPreconfiguration = (options, choices, choicePath = null) => {
  const { search } = window.location;
  const searchPath = search.substring(1) || choicePath;
  let path = searchPath;
  if (!path) {
      [,, path] = getFallbackPath();
    }

  const parts = Array.isArray(path) ? path : (path || '').split('&');
  const data = parts
  .map((p) => p.split('-', 1).map((n) => parseInt(n, 10)))
  .reduce((obj, [id]) => {
    if (!Number.isNaN(id) && id in choices) {
      // eslint-disable-next-line no-param-reassign
      obj[choices[id].optionId] = { choiceId: id };
    }

    return obj;
  }, {});
  return data;
};

export const getConfiguratorModeFromUrl = (configuratorMode) => {
  // see if Edit/Copy exist in url
  const { search } = window.location;
  const searchPath = search.substring(1);
  return searchPath.toLowerCase().includes('mode=' + configuratorMode.toLowerCase());
}

export function clearPersistedChoices() {
  return {
    type: CLEAR_PERSISTED_CHOICES,
  };
}