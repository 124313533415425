import { select, put, call, all } from 'redux-saga/effects';
import { getSkuPricing } from '../../api/skuMapperApi';
import { updateProductInfo } from '../../actions/pricingActions';
import { configSelector, configuratorSelector } from '../../selectors';
import getThdProductDetailsOrNotItsCool from './_getThdProductDetailsOrNotItsCool';
import { getPriceData } from './_utils';
import getOmsIdFromUrl from '../../utils/getOmsIdFromUrl';
import isDraftProduct from "../../utils/isDraftProduct";
const omsIdFromUrl = getOmsIdFromUrl

function* getSOPricing(skuMap, storeNumber) {
  const { sku, skuType } = skuMap;
    if (!!sku && !!skuType) {
      const { partnerProductId } = yield select(configSelector);
      const { productInfo } = yield select(configuratorSelector);

      const { productId } = productInfo;

      const [skuPrice, thdDetails] = yield all([
        call(getSkuPricing, sku, skuType, productId),
        call(getThdProductDetailsOrNotItsCool, isDraftProduct ? [omsIdFromUrl] : [partnerProductId] , storeNumber),
      ]);

      const newSkus = {
        ...skuMap,
        sku: isDraftProduct ? omsIdFromUrl : partnerProductId.toString(),
      };
      yield put(updateProductInfo({
        newSkus,
        thdDetails,
        skuPrice,
      }));

      return getPriceData(skuPrice.price, skuPrice.specialPrice);
  }

  return Promise.resolve();
}

export default getSOPricing;
