import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import flow from 'lodash/fp/flow';
import values from 'lodash/fp/values';
import filter from 'lodash/fp/filter';
import sortBy from 'lodash/fp/sortBy';
import map from 'lodash/fp/map';
import cookieUtils from 'cookie-utils';
import { Option } from '@gcc/configurator';
import { hasTagValue } from '../../utils/tags';
import Portal from '../../components/Portal';
import DraftBanner from '../../components/DraftBanner';

const hasHiddenTag = option => hasTagValue(option, 'pipfigurator', 'hidden');
global.cookieUtils = cookieUtils;
const hasPipFlipCookie = cookieUtils.fed_ReadCookie('tnt_pipflip');
const text = (isPipFlip) => hasPipFlipCookie && isPipFlip ? <span style={{ fontSize: '20px'}}>Create Your Own</span> : "Choose Your Options";
const renderOptions = flow(
  values,
  filter(option => (
    option.isActive &&
    !option.isDependent &&
    !option.isInternalOnly &&
    !hasHiddenTag(option)
  )),
  sortBy('sequence'),
  map(option => <Option key={option.id} option={option} />),
);

const Layout = ({
  options, details, isPipFlip
}) => {
  if (isEmpty(options.allIds)) {
    return null;
  }
  return (
    <div className="buybox" data-section="buybox">
      {details.isDraft && (
        <Portal node={document.querySelector('.hfapp')}>
          <DraftBanner details={details}/>
        </Portal>
      )}
      <div className="buybox__super-sku">
        <h2 className="buybox__section-title">{ text(isPipFlip) }</h2>
        <div>
          {renderOptions(options)}
         </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  options: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  homeDelivery: PropTypes.string,
};

Layout.defaultProps = {
  homeDelivery: false,
};

const mapStateToProps = (state) => {
  const {
    configurator: { options, choices },
    product: { details }
  } = state;
  return {
    options,
    choices,
    details,
  };
};

const options = {
  pure: true,
  areOwnPropsEqual: (prev, next) => isEqual(prev, next),
  areStatePropsEqual: (prev, next) => isEqual(prev, next),
  areMergedPropsEqual: (prev, next) => isEqual(prev, next),
};

export default connect(mapStateToProps, null, null, options)(Layout);
